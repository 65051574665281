import  React,{useState, useEffect} from "react"
import styled from 'styled-components';
import Login from './login';
import Document from './document';
import Template from './template';
import Loader from "./../assets/loading.gif";
import * as queryString from "query-string";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import  secureLocalStorage  from  "react-secure-storage";
import { getFromRedis, getAuthToken } from "./../graphql/queries";
import Amplify, { API, graphqlOperation } from 'aws-amplify';

export default function IndexPage(props) {
  const {consoleLog} = require('../Components/commonFunctions.js');
  const [isLoggedin, setIsLoggedin] = React.useState(false);
  const [loading, setLoading] = useState(true);

  const url_params = queryString.parse(props.location.search);

  async function checkIsUserLoggedIn(){
      if(secureLocalStorage.getItem('user_token')){
        ///auth
        consoleLog("checkIsUserLoggedIn >>>Token", secureLocalStorage.getItem('user_token'));
        let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
        consoleLog("checkIsUserLoggedIn >>>client_id", client_id);
        let client_token = secureLocalStorage.getItem('user_token');
        const user_auth = await API.graphql({
          query: getFromRedis,
          variables: {
            id: client_id,
            index: 'prodocs_user_auth_data',
          },
        });
        consoleLog("checkIsUserLoggedIn redis>>>user_auth", user_auth.data.getFromRedis);
        const user_authx = JSON.parse(user_auth.data.getFromRedis);
        consoleLog("checkIsUserLoggedIn redis>>>user_authx", user_authx);
        if(user_authx && user_authx?.status === 200){
          const user_authxx = JSON.parse(user_authx.body);
          consoleLog("checkIsUserLoggedIn redis>>>auth_client_id", user_authxx.client_id);
          consoleLog("checkIsUserLoggedIn redis>>>auth_client_token", user_authxx.token);
          if(client_id == user_authxx.client_id && client_token == user_authxx.token){
            consoleLog("checkIsUserLoggedIn redis>>>authentication authenticated");
          }else{
            secureLocalStorage.removeItem('client_data');
            secureLocalStorage.removeItem('user_token');
            secureLocalStorage.removeItem('es_down_docs');
            consoleLog("checkIsUserLoggedIn redis>>>authentication failed");
            navigate('/login');
            return false;
          }
        }else{
          const user_auth_db = await API.graphql({
            query: getAuthToken,
            variables: {
              client_id: client_id,
              token: client_token,
            },
          });
          consoleLog("checkIsUserLoggedIn >>>user_auth_db", user_auth_db.data.getAuthToken);
          const user_auth_dbx = JSON.parse(user_auth_db.data.getAuthToken);
          consoleLog("checkIsUserLoggedIn db>>>auth_client_id", user_auth_dbx.client_id);
          consoleLog("checkIsUserLoggedIn db>>>auth_client_token", user_auth_dbx.token);
          if(client_id == user_auth_dbx.client_id && client_token == user_auth_dbx.token){
            consoleLog("checkIsUserLoggedIn db>>>authenticated.");
          }else{
            secureLocalStorage.removeItem('client_data');
            secureLocalStorage.removeItem('user_token');
            secureLocalStorage.removeItem('es_down_docs');
            consoleLog("checkIsUserLoggedIn db>>>authentication failed.");
            navigate('/login');
            return false;
          }
        }
        ///auth
        if(url_params.id){
          let page = '/redirect?';
          for(const [key, value] of Object.entries(url_params)){
            page += `${key}=${value}&`;
          }
          navigate(page);
        }else if(!url_params.template_id && url_params.doc_type_id && !url_params.doc_id){
          navigate('/templates?doc_type_id='+url_params.doc_type_id);
        }
        else if(url_params.template_id && !url_params.doc_type_id && !url_params.doc_id){
          var nav = '/template?template_id='+url_params.template_id+"&is_template=1";
          navigate(nav);
        }
        else if(url_params.template_id && url_params.doc_type_id && url_params.doc_id){
          var nav = '/template?template_id='+url_params.template_id+'&&doc_type_id='+url_params.doc_type_id+'&&doc_id='+url_params.doc_id;
          navigate(nav);
        }
        else if(url_params.preview == 'wrkpdf'){
          var nav = '/materialCostPdf?preview=wrkpdf&doc_id='+url_params.doc_id;
          if(url_params.invoice == 1){
            nav = '/materialCostPdf?invoice=1&preview=wrkpdf&doc_id='+url_params.doc_id;
          }
          if(url_params.client_id && url_params.client_secret){
            nav += '&client_id='+url_params.client_id+'&client_secret='+url_params.client_secret;
          }
          if(url_params.serendipity_export){
            nav += '&serendipity_export='+url_params.serendipity_export;
          }
          navigate(nav);
        }
        else if(url_params.preview == 'estpdf'){
          var nav = '/pdftable?preview=estpdf&doc_id='+url_params.doc_id;
          if(url_params.invoice == 1){
            nav = '/pdftable?invoice=1&preview=estpdf&doc_id='+url_params.doc_id;
          }
          navigate(nav);
        }
        else if(url_params.preview == 'settings'){
          navigate('/settings');
        }
        else if(url_params.preview == 'faqs'){
          navigate('/faqs');
        }
        else{
          navigate('/document');
        }
      }else{
        if(url_params.user == 'admin'){
          navigate('/prodocs-admin-login?user=admin');
          return false;
        }
        if(url_params.id){
          let page = '/redirect?';
          for(const [key, value] of Object.entries(url_params)){
            page += `${key}=${value}&`;
          }
          navigate(page);
        }else{
          navigate('/login');
        }
      }
    setLoading(false);
  }

  useEffect(() => {
    checkIsUserLoggedIn();
  }, [isLoggedin]);

  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>Prodocs</title>
        <link rel="canonical" href="/prodocs-logo.png" />
     </Helmet>
       { loading &&
        <LoadingContainer>
            <img className="loader" src={Loader}/>
        </LoadingContainer>
      }
      {/*:

        (isLoggedin
          ?
          <Document />
          :
          <Login />
        )
      } */}
    </>
  )
}

const LoadingContainer = styled.div`
    height:100vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    background: transparent;
    .loader{
        -webkit-user-select: none;
        margin: auto;
        // background-color: rgba(255,255,255, 0.5);
        // transition: background-color 300ms;
    }
`
